import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import ValidateSubmit, { formatCurrency } from "wearslot-dev-utils";
import { responseMessage, errorResponse } from "../../libs/app";
import OrderItem, { OrderItemPlaceholder } from "./OrderItem";
import { useContext } from "react";
import { AuthContext } from "../../Contexts/AuthContext";
import OrderAddressForm from "./OrderAddressForm";
import moment from "moment";
import AddItemForm from "./AddItemForm";
import EditItemForm from "./EditItemForm";
import StatusForm from "./StatusForm";
import Placeholder from "../../Components/Placeholder/Placeholder";
import DeleteModal from "../../Components/DeleteModal";
import { OrderPaymentStatus } from "./Orders";
import getSymbolFromCurrency from 'currency-symbol-map';
import ScheduleDelivery from "./ScheduleDelivery";
import PaymentDetail from "./PaymentDetail";

const OrderDetail = (props) => {

  document.title = 'Order Details | Taojaa - Store Manager App'

  const { order_id } = useParams();
  const { store, subscription, connectedProviders, getConnectedDeliveryProviders } = useContext(AuthContext)

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [orderItemsLoading, setOrderItemsLoading] = useState(false)
  const [orderItems, setOrderItems] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [deliverySchedule, setDeliverySchedule] = useState(null);

  const [price, setPrice] = useState(0)
  const [item, setItem] = useState(null)
  const [quantity, setQuantity] = useState(0)
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [variation, setVariation] = useState(null)
  const [variations, setVariations] = useState([])
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState(null);

  const [address, setAddress] = useState({});
  const [addressType, setAddressType] = useState(null);
  const [asOther, setAsOther] = useState(false);

  const [sendMail, setSendMail] = useState(false);

  const [status, setStatus] = useState({
    status: null,
    date: '',
    note: ''
  });
  const [payment, setPayment] = useState({
    status: null,
    payment_method: null,
  });

  const [modal, setModal] = useState(null);

  const productOptions =
    products.length > 0 &&
    products.map(({ id, name }) => {
      return { value: id, label: name };
    });

  const subTotal = orderItems.reduce((accummulator, orderItem) => {
    return accummulator + (Number(orderItem.price) * orderItem.quantity);
  }, 0);

  const shippingFee = orderDetails ? orderDetails.shipping_fee : 0;
  const discount = orderDetails?.discount.amount;
  const total = subTotal + shippingFee - discount;

  useEffect(() => {
    getOrderDetails();
    getOrderItems();
    getConnectedDeliveryProviders();
    getOrderDeliverySchedule();
  }, []);

  useEffect(() => {
    if (item !== null) {
      getAvailableVariations(item.product)
      setVariation(item.variation)
      setQuantity(item.quantity)
      setPrice(item.price)
    }
  }, [item]);

  useEffect(() => {
    if (selectedOption !== null) {
      selectOption(selectedOption)
    }
  }, [selectedOption]);

  useEffect(() => {
    const interval = setTimeout(() => {
      orderDetails && updateOrder({ id: orderDetails.id, amount: subTotal });
    }, 5000);

    return () => clearTimeout(interval);
  }, [subTotal]);

  async function getProducts() {

    try {

      const response = await axios.get(`/products`);
      if (response.data.success) {
        let resFromArray = response.data.data.map(res => res)
        if (resFromArray) {
          var list = resFromArray;
          setProducts(list);
        }
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
    }
  }

  async function getAvailableVariations(product) {
    try {
      const response = await axios.get(`/product/variations/${product.id}`);
      if (response.data.success) {
        setVariations(response.data.variations);
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
    }
  }

  async function getOrderDetails() {
    try {
      const response = await axios.get(`/order/${order_id}`);
      if (response.data.success === true) {
        var order = response.data.data;
        if (order.billing_address && order.shipping_address) {
          if (order.billing_address.id === order.shipping_address.id) {
            setAsOther(true);
          }
        }

        setOrderDetails(order);
        setLoading(false)
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  async function getOrderItems() {
    try {
      setOrderItemsLoading(true)
      const response = await axios.get(`/order/items/${order_id}`);
      if (response.data.success)
        setOrderItems(response.data.items);

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
    }

    setOrderItemsLoading(false)
  }

  async function getOrderDeliverySchedule() {
    try {
      const response = await axios.get(`/order/delivery/${order_id}`);
      if (response.data.success)
        setDeliverySchedule(response.data.data);

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  async function updateOrder(data, showMessage = false) {
    try {
      const response = await axios.put(`/order/update`, data);
      if (response.data.success) {
        if (showMessage) {
          responseMessage(response.data.message, "success");
        }
      }

    } catch (error) {
      if (showMessage) {
        if (error.response)
          errorResponse(error.response);
        else
          responseMessage("Something went wrong.", "error");
      }
    }
  }

  async function updateItem(id) {
    try {
      setSaving(true)
      let data = {
        item_id: item.id,
        price,
        quantity,
      };

      if (variation !== null) {
        data.variation = variation.id;
      }

      const response = await axios.put(`/order/item/update`, data);
      if (response.data.success) {
        responseMessage(response.data.message, "success");
        getOrderItems();

        closeModal()
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setSaving(false)
  }

  async function updateStatus() {

    try {

      setSaving(true)

      const data = {
        ...status,
        id: order_id,
        sendMail
      };

      const response = await axios.put('/order/status/update', data);
      if (response.data.success) {
        responseMessage(response.data.message, "success");
        getOrderDetails();

        closeModal()
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setSaving(false)
  }

  async function savePayment() {

    try {

      setSaving(true)

      const data = {
        ...payment,
        id: order_id
      };

      const response = await axios.put('/order/payment/update', data);
      if (response.data.success) {
        responseMessage(response.data.message, "success");
        getOrderDetails();

        closeModal()
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setSaving(false)
  }

  async function saveItem() {

    try {

      setSaving(true)

      let data = {
        order_id: order_id,
        product_id: product.id,
        price,
        quantity,
      };

      if (variation !== null) {
        data.variation = variation.id;
      }

      const response = await axios.post(`/order/add/item`, data);
      if (response.data.success) {
        responseMessage(response.data.message, "success");
        getOrderItems();

        closeModal();
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setSaving(false)
  }

  async function saveAddress(type) {

    try {

      setSaving(true)

      if (!ValidateSubmit()) {
        return;
      }

      const data = {
        order_id: order_id,
        firstname: address.firstname,
        lastname: address.lastname,
        phone: address.phone,
        street: address.street,
        additional_address: address.additional_address,
        city: address.city,
        zip: address.zip,
        state: address.state,
        country: address.country,
        as_other: asOther
      };


      const response = await axios.post(`/order/address/${type}`, data);

      if (response.data.success) {
        responseMessage(response.data.message, "success");
        getOrderDetails();

        closeModal()
      }

    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }

    setSaving(false)
  }

  async function deleteOrderItem() {
    try {
      const response = await axios.delete(`/order/item/remove/${selectedItemForDelete}`);
      if (response.data.success === true) {
        responseMessage(response.data.message, "success");
        getOrderItems();
        closeDeleteModal()
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    }
  }

  async function createDeliverySchedule(delivery) {
    try {
      setSaving(true);
      const payload = {
        order_id: orderDetails?.id,
        ...delivery
      };
      const response = await axios.post('/services/delivery/schedule-pickup-and-delivery', payload);
      if (response.data.status === 'success') {
        responseMessage(response.data.message, 'success')
        getOrderDeliverySchedule()
        closeModal()
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    } finally {
      setSaving(false)
    }
  }

  async function getDeliveryFee(delivery, setDeliveryRate) {
    try {
      setSaving(true);
      if (!orderDetails.shipping_address) {
        return responseMessage("Shipping address missing, kindly add address to order.", "error");
      }

      const payload = {
        store_id: store.store_id,
        name: `${orderDetails.shipping_address.firstname} ${orderDetails.shipping_address.lastname}`,
        address: `${orderDetails.shipping_address.street || ''} ${orderDetails.shipping_address.additional_address || ''}, ${orderDetails.shipping_address.city || ''} ${orderDetails.shipping_address.state && orderDetails.shipping_address.state || ''} ${orderDetails.shipping_address.country && orderDetails.shipping_address.country || ''} ${orderDetails.shipping_address.zip || ''}`,
        phone: orderDetails.shipping_address.phone || '',
        items: orderItems,
        currency: orderDetails.currency,
        ...delivery
      };
      const response = await axios.post('/services/delivery/provider-rate', payload);
      if (response.data.status === 'success') {
        setDeliveryRate(response.data.rate)
        responseMessage(response.data.message, 'success')
      }
    } catch (error) {
      if (error.response)
        errorResponse(error.response);
      else
        responseMessage("Something went wrong.", "error");
    } finally {
      setSaving(false);
    }
  }

  const cancelOrder = () => {
    setStatus({ status: 0, date: '' });
    openModal('order-status-modal')
  }

  const deliveryScheduleModal = () => {
    openModal('schedule-delivery-modal');
  }

  const addItemModal = () => {
    getProducts();
    setVariations([])

    openModal('add-item-modal')
  }

  const editOrderItem = (item) => {
    setItem(item)

    openModal('edit-item-modal')
  }

  const selectVariation = (option) => {
    setQuantity(1)
    setVariation(option)
    setPrice(option.price)
  }

  const selectOption = (selected) => {

    var target = products.find(current => selected.value === current.id);
    setProduct(target);
    getAvailableVariations(target)
    setQuantity(1)
    setPrice(target.price)
  }

  const clearAddItem = () => {
    setProduct(null);
    setVariation(null)
  }

  const clearEditItem = () => {
    setItem(null);
    setVariation(null)
  }

  const clearAddressForm = () => {
    setAddressType(null);
    setAsOther(false)
  }

  const addOrderAddress = (type) => {
    setAddressType(type);
    openModal('order-address-modal')
  }

  const updateOrderAddress = (address, type) => {
    setAddress(address)
    setAddressType(type);
    openModal('order-address-modal')
  }

  const updatePaymentDetails = () => {
    openModal('payment-details')
  }

  function handleChange(event) {
    const { value, name } = event.target;
    name === "price" ? setPrice(value) : setQuantity(value);
  }

  function handleStatusChange(event) {
    const { value, name } = event.target;
    setStatus({ ...status, [name]: value });
  }

  function handlePaymentChange(event) {
    const { value, name } = event.target;
    setPayment({ ...payment, [name]: value });
  }

  function handleAddressChange(event) {
    const { value, name } = event.target;
    setAddress({ ...address, [name]: value })
  }

  const openModal = (target) => setModal(target);
  const closeModal = () => {
    setModal(null);
    setStatus({ date: '', status: null })
    setPayment({payment_method: null, status: null})
    clearAddItem()
    clearEditItem()
    clearAddressForm()
  }

  const closeDeleteModal = () => {
    setSelectedItemForDelete(null)
  }

  const similarPropsList = {
    saving,
    closeModal
  };

  const ItemsModalSimilarProps = {
    ...similarPropsList,
    order: orderDetails,
    price,
    product,
    quantity,
    variation,
    variations,
    selectVariation,
    handleChange
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          {!loading ?
            <>
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Order Details</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="#" onClick={() => window.history.back()}>
                            <i className="ri-arrow-left-line align-bottom me-1"></i>
                            Back
                          </Link>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center justify-content-between">
                        <div className="col-md-6">
                          <h5 className="card-title flex-grow-1 mb-0">
                            Order No. {orderDetails && orderDetails.order_no}
                          </h5>
                        </div>

                        <div className="col-md-6 text-end gap-2">
                          <a
                            href="#addItemModal"
                            className="btn btn-primary btn-sm mt-2 mt-sm-0"
                            onClick={addItemModal}
                          >
                            <i className="mdi mdi-basket-plus align-bottom me-1"></i>
                            Add Item
                          </a>
                          {orderDetails && <Link className="btn btn-success ms-2 btn-sm" to={`/invoices/create/${orderDetails.id}`}>
                            <i className="mdi mdi-clipboard-text-outline align-bottom me-1"></i>
                            Generate Invoice
                          </Link>}

                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive table-card">
                        <table className="table table-nowrap align-middle table-borderless mb-0">
                          <thead className="table-light text-muted">
                            <tr>
                              <th scope="col">Product Details</th>
                              <th scope="col">Price</th>
                              <th scope="col">Quantity</th>
                              <th scope="col" className="text-end">
                                Total Amount
                              </th>
                              {props.update === true ? (
                                <th scope="col">Action</th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {orderItems.length > 0 ? (
                              <>
                                {orderItems.map((item, index) => (
                                  <OrderItem
                                    key={index}
                                    item={item}
                                    order={orderDetails}
                                    update={props.update}
                                    editOrderItem={editOrderItem}
                                    setItemForDelete={setSelectedItemForDelete}
                                  />
                                ))}
                              </>
                            ) :
                              <>
                                {orderItemsLoading
                                  ? <OrderItemPlaceholder update={props.update} />
                                  : <tr><td colSpan={4} className="text-center text-danger py-4">This order has no item.</td></tr>
                                }
                              </>
                            }
                            <tr className="border-top border-top-dashed">
                              <td colSpan="2"></td>
                              <td colSpan="2" className="fw-medium p-0">
                                <table className="table table-borderless mb-0">
                                  <tbody>
                                    <tr>
                                      <td>Sub Total :</td>
                                      <td className="text-end">
                                        {getSymbolFromCurrency(orderDetails.currency) + formatCurrency(subTotal)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <p className="m-0 p-0">Discount <span className="text-muted">
                                          {orderDetails ? `(${orderDetails.discount.coupon_code})` : ''}
                                        </span>:</p>
                                      </td>
                                      <td className="text-end">
                                        - {getSymbolFromCurrency(orderDetails.currency) + formatCurrency(discount)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Shipping Charge:</td>
                                      <td className="text-end">
                                        {getSymbolFromCurrency(orderDetails.currency)}{orderDetails && formatCurrency(orderDetails.shipping_fee)}
                                      </td>
                                    </tr>
                                    <tr className="border-top border-top-dashed">
                                      <th scope="row">
                                        Total:
                                      </th>
                                      <th className="text-end">
                                        {getSymbolFromCurrency(orderDetails.currency) + formatCurrency(total)}
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <div className="d-sm-flex align-items-center">
                        <h5 className="card-title flex-grow-1 mb-0">
                          Order History
                        </h5>
                        {orderDetails && orderDetails.cancelled != 1 ? (
                          <div className="flex-shrink-0 text-end mt-2 mt-sm-0">
                            <button
                              type="button"
                              role="button"
                              className="btn btn-primary btn-sm mt-2 mt-sm-0"
                              onClick={() => openModal('order-status-modal')}
                            >
                              Update Status
                            </button>
                            {deliverySchedule === null && orderDetails?.cancelled != 1 && orderDetails.status < 4 ?
                              <button
                                type="button"
                                role="button"
                                onClick={deliveryScheduleModal}
                                className="btn btn-secondary btn-sm mt-2 ms-2 mt-sm-0"
                              >
                                <i className="mdi mdi-truck align-bottom me-1"></i>
                                Schedule Delivery
                              </button>
                              : <Fragment>
                                {deliverySchedule && deliverySchedule.delivery_details.pickups[0].auto_assignment_data.job_pickup_datetime > moment().subtract(1, 'h').format('YYYY-MM-DD HH:mm:ss') &&
                                  <button
                                    type="button"
                                    role="button"
                                    onClick={deliveryScheduleModal}
                                    className="btn btn-secondary d-none btn-sm mt-2 ms-2 mt-sm-0"
                                  >
                                    <i className="mdi mdi-truck align-bottom me-1"></i>
                                    Cancel Delivery
                                  </button>
                                }
                              </Fragment>
                            }
                            <button
                              type="button"
                              role="button"
                              onClick={cancelOrder}
                              className="btn btn-danger btn-sm mt-2 ms-2 mt-sm-0"
                            >
                              <i className="mdi mdi-archive-remove-outline align-bottom me-1"></i>
                              Cancel Order
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="profile-timeline">
                        {orderDetails && orderDetails.cancelled === 1 ? (
                          <div className="alert alert-borderless alert-danger" role="alert">
                            <strong> This Order has been cancelled!</strong>
                          </div>
                        ) : deliverySchedule && orderDetails?.status < 4 &&
                        <div className="alert alert-borderless alert-primary" role="alert">
                          <strong className="fs-5">Delivery Scehdule</strong>
                          <p className="mb-0">This order has been scehduled for delivery and will be picked by the logictics/delivery provider at <b>{deliverySchedule.delivery_details.pickups[0].auto_assignment_data.job_pickup_datetime}</b></p>
                          <p>Kindly make sure items are ready and well sealed.</p>
                        </div>}

                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="accordion-item border-0">
                            <div className="accordion-header" id="headingOne">
                              <a
                                className="accordion-button p-2 shadow-none"
                                data-bs-toggle="collapse"
                                href="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0 avatar-xs">
                                    <div className="avatar-title bg-primary rounded-circle">
                                      <i className="ri-shopping-bag-line"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <h6 className="fs-15 mb-0 fw-semibold">
                                      Order Placed -
                                      <span className="fw-normal">
                                        {orderDetails && moment(orderDetails.created_date).format('ddd, D MMM YYYY - h:mmA')}
                                      </span>
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body ms-2 ps-5 pt-0">
                                <h6 className="mb-1">Order was placed.</h6>
                                <p className="text-muted">
                                  {orderDetails && moment(orderDetails.created_date).format('ddd, D MMM YYYY - h:mmA')}
                                </p>
                                {orderDetails &&
                                  orderDetails.confirmed_date ?
                                  <>
                                    <h6 className="mb-1">
                                      You confirmed this order.
                                    </h6>
                                    <p className="text-muted mb-0">
                                      {moment(orderDetails.confirmed_date).format('ddd, D MMM YYYY - h:mmA')}
                                    </p>
                                  </>
                                  : null
                                }
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item border-0">
                            {orderDetails && orderDetails.status >= 3 ?
                              <>
                                <div className="accordion-header" id="headingTwo">
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-primary rounded-circle">
                                          <i className="mdi mdi-gift-outline"></i>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-15 mb-1 fw-semibold">
                                          Packed -
                                          <span className="fw-normal">
                                            {(orderDetails && orderDetails.processed_date) && moment(orderDetails.processed_date).format('ddd, D MMM YYYY - h:mmA')}
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapseTwo"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5 pt-0">
                                    <h6 className="mb-1">
                                      Order has been packed and ready for shipping.
                                    </h6>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                                <div className="accordion-header" id="headingTwo">
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapseTwo"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-light text-primary rounded-circle">
                                          <i className="mdi mdi-gift-outline"></i>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-0 fw-semibold">
                                          Packed
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </>
                            }
                          </div>
                          <div className="accordion-item border-0">
                            {orderDetails && orderDetails.status >= 4 ?
                              <>
                                <div className="accordion-header" id="headingThree">
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-primary rounded-circle">
                                          <i className="ri-truck-line"></i>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-15 mb-1 fw-semibold">
                                          Shipping -
                                          <span className="fw-normal">
                                            {(orderDetails && orderDetails.shipped_date) && moment(orderDetails.shipped_date).format('ddd, D MMM YYYY - h:mmA')}
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapseThree"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5 pt-0">
                                    {/* <h6 className="fs-14">
                                      RQK Logistics - MFDS1400457854
                                    </h6> */}
                                    <h6 className="mb-1">
                                      Order has been shipped.
                                    </h6>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                                <div className="accordion-header" id="headingThree">
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapseThree"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-light text-primary rounded-circle">
                                          <i className="ri-takeaway-fill"></i>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-0 fw-semibold">
                                          Shipping
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </>
                            }
                          </div>
                          <div className="accordion-item border-0">
                            {orderDetails && orderDetails.status == 5 ?
                              <>
                                <div className="accordion-header" id="headingThree">
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-primary rounded-circle">
                                          <i className="mdi mdi-package-variant"></i>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-15 mb-1 fw-semibold">
                                          Delivered -
                                          <span className="fw-normal">
                                            {(orderDetails && orderDetails.completed_date) && moment(orderDetails.completed_date).format('ddd, D MMM YYYY - h:mmA')}
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  id="collapseThree"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="headingThree"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body ms-2 ps-5 pt-0">
                                    <h6 className="mb-1">
                                      Order has been delivered.
                                    </h6>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                                <div className="accordion-header" id="headingFour">
                                  <a
                                    className="accordion-button p-2 shadow-none"
                                    data-bs-toggle="collapse"
                                    href="#collapseFile"
                                    aria-expanded="false"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 avatar-xs">
                                        <div className="avatar-title bg-light text-primary rounded-circle">
                                          <i className="mdi mdi-package-variant"></i>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h6 className="fs-14 mb-0 fw-semibold">
                                          Delivered
                                        </h6>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </>
                            }
                          </div>
                          {orderDetails && orderDetails.cancelled === 1 ?
                            <div className="accordion-item border-0">
                              <div className="accordion-header" id="headingThree">
                                <a
                                  className="accordion-button p-2 shadow-none"
                                  data-bs-toggle="collapse"
                                  href="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 avatar-xs">
                                      <div className="avatar-title bg-danger rounded-circle">
                                        <i className="mdi mdi-close"></i>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                      <h6 className="fs-15 mb-1 fw-semibold">
                                        Cancelled -
                                        <span className="fw-normal">
                                          {(orderDetails && orderDetails.cancelled_date) && moment(orderDetails.cancelled_date).format('ddd, D MMM YYYY - h:mmA')}
                                        </span>
                                      </h6>
                                    </div>
                                  </div>
                                </a>
                              </div>
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body ms-2 ps-5 pt-0">
                                  <h6 className="mb-1">
                                    Order has been cancelled.
                                  </h6>
                                </div>
                              </div>
                            </div>
                            : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 mt-2">
                  <div className="row">
                    <div className="col-xl-4">
                      <div className="card card-height-100">
                        <div className="card-header">
                          <div className="d-flex">
                            <h5 className="card-title flex-grow-1 mb-0">
                              Customer Details
                            </h5>
                          </div>
                        </div>
                        <div className="card-body">
                          <ul className="list-unstyled mb-0 vstack gap-3">
                            <li>
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 ms-3">
                                  <h6 className="fs-14 mb-1">
                                    {orderDetails && orderDetails.customer.name || "N/A"}
                                  </h6>
                                  <p className="text-muted mb-0">Customer</p>
                                </div>
                              </div>
                            </li>
                            <li>
                              <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                              {orderDetails && orderDetails.customer.email || "N/A"}
                            </li>
                            <li>
                              <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                              {orderDetails && orderDetails.customer.phone || "N/A"}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="card card-height-100">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <h5 className="card-title mb-0">
                              <i className="ri-map-pin-line align-middle me-1 text-muted"></i>
                              Billing Address
                            </h5>
                            {orderDetails && orderDetails.billing_address ? (
                              <a
                                href="#addressModal"
                                onClick={(e) => updateOrderAddress(orderDetails.billing_address, "billing-address")}
                                className="text-decoration-underline"
                              >
                                <b>Change</b>
                              </a>
                            ) : null}
                          </div>
                        </div>
                        <div className="card-body">
                          {orderDetails && orderDetails.billing_address ? (
                            <>
                              <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                                <li className="fw-medium fs-14">{orderDetails.billing_address.firstname} {orderDetails.billing_address.lastname}</li>
                                {orderDetails.billing_address.phone && <li>{orderDetails.billing_address.phone}</li>}
                                <li>{orderDetails.billing_address.street}, {orderDetails.billing_address.city}</li>
                                {orderDetails.billing_address.state && (<li>{orderDetails.billing_address.state}</li>)}
                                <li>{orderDetails.billing_address.country && orderDetails.billing_address.country} {orderDetails.billing_address.zip}</li>
                              </ul>
                            </>
                          ) :
                            <div className="d-flex h-100 justify-content-center align-items-center">
                              <button className="btn btn-sm btn-primary"
                                onClick={() => addOrderAddress("billing-address")}>
                                <i className="ri-map-pin-line align-middle me-1 text-white"></i> Add Address
                              </button>
                            </div>}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="card card-height-100">
                        <div className="card-header">
                          <div className="d-flex justify-content-between">
                            <h5 className="card-title mb-0">
                              <i className="ri-map-pin-line align-middle me-1 text-muted"></i>
                              Shipping Address
                            </h5>
                            {orderDetails && orderDetails.shipping_address ? (
                              <a
                                href="#addressModal"
                                onClick={(e) => updateOrderAddress(orderDetails.shipping_address, "shipping-address")}
                                className="text-decoration-underline"
                              >
                                <b>Change</b>
                              </a>
                            ) : null}
                          </div>
                        </div>
                        <div className="card-body">
                          {orderDetails && orderDetails.shipping_address ? (
                            <>
                              <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                                <li className="fw-medium fs-14">{orderDetails.shipping_address.firstname} {orderDetails.shipping_address.lastname}</li>
                                {orderDetails.shipping_address.phone && <li>{orderDetails.shipping_address.phone}</li>}
                                <li>{orderDetails.shipping_address.street}, {orderDetails.shipping_address.city}</li>
                                {orderDetails.shipping_address.state && (<li>{orderDetails.shipping_address.state}</li>)}
                                <li>{orderDetails.shipping_address.country && orderDetails.shipping_address.country} {orderDetails.shipping_address.zip}</li>
                              </ul>
                            </>
                          ) :
                            <div className="d-flex h-100 justify-content-center align-items-center">
                              <button className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#addressModal"
                                onClick={() => addOrderAddress("shipping-address")}>
                                <i className="ri-map-pin-line align-middle me-1 text-white"></i> Add Address
                              </button>
                            </div>}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="card card-height-100">
                        <div className="card-header">
                          <div class="d-flex justify-content-between">
                            <h5 className="card-title mb-0">
                              <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i>
                              Payment Details
                            </h5>
                            <a
                              href="#paymentModal"
                              onClick={updatePaymentDetails}
                              className="text-decoration-underline"
                            >
                              <b>Update</b>
                            </a>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="text-muted mb-0">Payment Method:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="mb-0">
                                {orderDetails && orderDetails.payment_method?.name || "N/A"}
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                              <p className="text-muted mb-0">Payment Status:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="mb-0">
                                <OrderPaymentStatus status={orderDetails?.payment_status} />
                              </h6>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                              <p className="text-muted mb-0">Total Amount:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">
                              <h6 className="mb-0">
                                {getSymbolFromCurrency(orderDetails.currency) + formatCurrency(total)}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            :
            <>
              <div className='mb-2'><Placeholder column="col-9" /></div>
              <div className='mb-2'><Placeholder column="col-12" /></div>
              <div className='mb-2'><Placeholder column="col-7" /></div>
              <div className='mb-2'><Placeholder column="col-10" /></div>
            </>
          }

        </div>
      </div>

      <AddItemForm
        {...ItemsModalSimilarProps}
        show={modal === 'add-item-modal' ? true : false}
        products={products}
        productOptions={productOptions}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        saveItem={saveItem}
      />

      <EditItemForm
        {...ItemsModalSimilarProps}
        show={modal === 'edit-item-modal' ? true : false}
        item={item}
        orderItems={orderItems}
        updateItem={updateItem}
      />

      <OrderAddressForm
        {...similarPropsList}
        show={modal === 'order-address-modal' ? true : false}
        address={address}
        asOther={asOther}
        type={addressType}
        setAsOther={setAsOther}
        handleChange={handleAddressChange}
        saveAddress={saveAddress}
      />

      <StatusForm
        {...similarPropsList}
        show={modal === 'order-status-modal' ? true : false}
        status={status}
        sendMail={sendMail}
        subscription={subscription}
        setSendMail={setSendMail}
        handleChange={handleStatusChange}
        updateStatus={updateStatus}
      />

      <ScheduleDelivery
        {...similarPropsList}
        order={orderDetails}
        connectedProviders={connectedProviders}
        show={modal === 'schedule-delivery-modal' ? true : false}
        getDeliveryFee={getDeliveryFee}
        createDeliverySchedule={createDeliverySchedule}
      />

      <PaymentDetail
        {...similarPropsList}
        show={modal === 'payment-details' ? true : false}
        payment={payment}
        handleChange={handlePaymentChange}
        updatePayment={savePayment}
      />

      <DeleteModal
        show={selectedItemForDelete ? true : false}
        title={'Remove Confirmation'}
        message={'Are you sure you want to remove this item?'}
        handleDelete={deleteOrderItem}
        handleClose={closeDeleteModal}
      />

      <Outlet />
    </>
  );
};



export default OrderDetail;
