import React, { useContext } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { AuthContext } from '../../Contexts/AuthContext'
import moment from 'moment'

import Subverse from '../../assets/subverse.png'
import getSymbolFromCurrency from 'currency-symbol-map'


const BillingAndSubscription = ({ expired = false }) => {

  document.title = 'Billing & Subscription | Taojaa - Store Manager App';

  const { subscription } = useContext(AuthContext);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          {(subscription && !subscription?.expired && !expired) && (
            <div className="text-center py-5">
              <div className="mb-4">
                <lord-icon src="https://cdn.lordicon.com/lupuorrc.json"
                  trigger="loop" colors="primary:#695eef,secondary:#73dce9"
                  style={{ width: "120px", height: "120px" }}></lord-icon>
              </div>

              {(subscription && subscription.is_trial) ?
                <>
                  <h5 className='mb-0'>You're Currently on {subscription.plan.group_name} Free Trial Plan</h5>
                  <p className='mt-0'>Your trial will end on <b>{moment(subscription?.end_date).format('ddd, D MMM YYYY')}</b>, upgrade now to continue enoying unlimted access.</p>
                </>
                : <>
                  <h5 className='mb-0'>You're Currently on {subscription.plan.name} {subscription.plan.group_name} Plan</h5>
                </>
              }

              {subscription && (subscription?.plan.group_name !== "Premium" || subscription.is_trial) &&
                <>
                  <p className='mt-3'>You can get more with premium! <a href={'https://taojaa.com/pricing'} target='_blank' className='text-primary'>Learn more </a></p>
                </>
              }

            </div>
          )}

          {expired &&
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className='card'>
                  <div className='card-body'>
                    <div className="text-center mb-4 pb-2">
                      <img src={Subverse} alt='Growth Image' />
                      <h4 className="fw-semibold fs-22"> {subscription?.is_trial ? 'Your Trial has Expired' : 'Your Subsription has Expired'}</h4>
                      <p className="mb-4 fs-15">
                        Your {subscription?.plan.name.toLowerCase()} {subscription?.plan.group_name.toLowerCase()} {subscription?.is_trial ? 'trial period' : 'plan'}  has expired on {moment(subscription?.end_date).format('ddd, D MMM YYYY')}.<br /> Select a plan to continue enjoying access to smart tools.
                      </p>
                      <a className='btn btn-sm btn-primary' href={'#Plans'}>Choose Plan</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="row justify-content-center mt-5">
            <div className="col-lg-5">
              <div className="text-center mb-4 pb-2">
                <h4 className="fw-semibold fs-22">Choose the right plan for your business</h4>
                <p className="mb-4 fs-15">Simple pricing, no hidden fees. Advanced features for your business. Cancel anytime.</p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className={`col-xl-${expired ? '9' : '10'}`} id="Plans">
              <div className="row justify-content-center">

                <div className={`col-lg-4`}>
                  <div className="card pricing-box card-height-100 ribbon-box">
                    <div className="card-body p-3 m-2">
                      {(subscription && !subscription.expired && !subscription.is_trial && subscription.plan?.group_name === 'Professional')
                        ? <div className="ribbon-two ribbon-two-danger"><span>Active</span></div>
                        : <div className="ribbon-two ribbon-two-danger"><span>Popular</span></div>}
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h5 className="mb-1 fw-semibold">Professional</h5>
                          <p className="text-muted mb-0">For Individuals & Small Businesses</p>
                        </div>
                        <div className="avatar-sm">
                          <div className="avatar-title bg-light rounded-circle text-primary">
                            <i className="ri-book-mark-line fs-20"></i>
                          </div>
                        </div>
                      </div>
                      <div className="pt-4">
                        <h2><sup><small>{getSymbolFromCurrency('NGN')}</small></sup>7,500<span className="fs-13 text-muted">/Month</span></h2>
                      </div>
                      <hr className="my-4 text-muted" />
                      <div>
                        <ul className="list-unstyled text-muted vstack gap-3">
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Online Store
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Up to <b>500</b> product listing
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Sales Channels (Google Only)
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Unlimited Invoicing & Receipts
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Unlimited Discounts & Coupons
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Payment Integration + (1.5% fee)
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                5 Payment Links
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Multi-Currency Access
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Analytics & Reports (Basic)
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Custom Domain Integration
                              </div>
                            </div>
                          </li>
                          {/* <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                1 Year Free .com.ng Domain
                              </div>
                            </div>
                          </li> */}
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                1 Staff Accounts
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Google Analytics & Facebook Pixels
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div className="mt-4">
                          {subscription?.plan.group_name === 'Professional' && !subscription.is_trial && !subscription.expired
                            ? <Link to={`#`} className="btn btn-success w-100 waves-effect waves-light">Active</Link>
                            : <Link to={`${subscription === null || subscription?.expired ? '/dashboard/access' : ''}/subscribe/Professional/plans`} className="btn btn-danger w-100 waves-effect waves-light">Upgrade Now</Link>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`col-lg-4`}>
                  <div className="card pricing-box card-height-100 ribbon-box right">
                    <div className="card-body p-3 m-2">
                      {(subscription && !subscription.expired && !subscription.is_trial && subscription.plan?.group_name === 'Premium')
                        && <div className="ribbon-two ribbon-two-danger"><span>Active</span></div>}

                      <div>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1">
                            <h5 className="mb-1 fw-semibold">Premium</h5>
                            <p className="text-muted mb-0">For Growing Businesses</p>
                          </div>
                          <div className="avatar-sm">
                            <div className="avatar-title bg-light rounded-circle text-danger">
                              <i className="ri-medal-line fs-20"></i>
                            </div>
                          </div>
                        </div>
                        <div className="pt-4">
                          <h2><sup><small>{getSymbolFromCurrency('NGN')}</small></sup>12,750<span className="fs-13 text-muted">/Month</span></h2>
                        </div>
                      </div>
                      <hr className="my-4 text-muted" />
                      <div>
                        <ul className="list-unstyled text-muted vstack gap-3">
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                <b>All in Professional +</b>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Unlimited items listing
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Sales Channels (Unlimited)
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Inventory Tracking + Notifications
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Abandoned Carts + Notifications
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                2000 Monthly Campaign Credit
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Unlimited Payment Links
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Analytics & Reports (Standard)
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                5 Staffs Account
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Search Engine Optimization
                              </div>
                            </div>
                          </li>
                          <li className='mb-5'>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1 mb-3">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Developer & Technical Support
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div className="" style={{ marginTop: 40 }}>
                          {subscription?.plan.group_name === 'Premium' && !subscription.expired
                            ? !subscription.is_trial
                              ? <Link to={`#`} className="btn btn-success w-100 waves-effect waves-light">Active</Link>
                              : <Link to={`${subscription === null || subscription?.expired ? '/dashboard/access' : ''}/subscribe/Premium/plans`} className="btn btn-primary w-100 waves-effect waves-light">Upgrade Now</Link>
                            : <Link to={`${subscription === null || subscription?.expired ? '/dashboard/access' : ''}/subscribe/Premium/plans`} className="btn btn-primary w-100 waves-effect waves-light">Upgrade Now</Link>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default BillingAndSubscription