import $ from "jquery";
import { AppContext } from "../../Contexts/AppContext";
import { AuthContext } from "../../Contexts/AuthContext";
import { Link } from "react-router-dom";
import LogoDark from "./Logos/LogoDark";
import LogoLight from "./Logos/LogoLight";
import { OrderStatus } from "../../Pages/Orders/Orders";
import React from "react";
import SimpleBar from "simplebar-react";
import axios from "axios";
import { formatCurrency } from "wearslot-dev-utils";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import { responseMessage } from "../../libs/app";

const Header = () => {
  const { user, store, logoutUser, storeFrontToken, domain } = React.useContext(AuthContext);
  const { changeLayout, changeTheme, toggleSidebar } =
    React.useContext(AppContext);

  const DEBOUNCE = 1000;
  const [keyword, setKeyword] = React.useState(null);
  const [searchResponse, setSearchResponse] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const [unread, setUnread] = React.useState(0);

  React.useEffect(() => {
    getNotifications();

    const listener = document.addEventListener("click", (e) => {
      if (!e.target.closest(".notifications-box")) {
        $("#header-notifications").removeClass("show");
      }

      if (!e.target.closest(".app-search")) {
        $("#search-dropdown").hide();
      }
    });
    return document.removeEventListener("click", listener);
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (keyword !== null) {
        getSearchResponse(keyword);
      }
    }, [DEBOUNCE]);

    return () => clearTimeout(timeout);
  }, [keyword]);

  const handleSearch = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearchClose = (e) => {
    if (!keyword) {
      $("#search-dropdown").hide();
    }
  };

  const getSearchResponse = async (keyword) => {
    try {
      const response = await axios.get(`/search?keyword=${keyword}`);
      if (response.data.success) {
        setSearchResponse(response.data.data);
      }
    } catch (error) { }
  };

  const getNotifications = async () => {
    try {
      const response = await axios.get(`/notifications`);
      if (response.data.success) {
        setNotifications(response.data.notifications);
        setUnread(response.data.unread);
      }
    } catch (error) { }
  };

  const markAllAsRead = async () => {
    try {
      const response = await axios.post(`/notifications/mark-as-read/all`);
      if (response.data.success) {
        setUnread(0);
        responseMessage(response.data.message);
      }
    } catch (error) { }
  };

  const NotificationIcons = ({ notification }) => {
    switch (notification.type) {
      case "alert":
        return (
          <span className="avatar-title bg-soft-danger text-danger rounded-circle fs-16">
            <i className="bx bx-bell"></i>
          </span>
        );

      default:
        return (
          <span className="avatar-title bg-soft-info text-info rounded-circle fs-16">
            <i className="bx bx-message-square-dots"></i>
          </span>
        );
    }
  };

  const formatDateTimeDisplay = (date) => {
    var now = moment();
    date = moment(date);

    var formated = "";

    return date.format("ddd, D MMM YYYY");
  };

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box horizontal-logo">
              <LogoDark />
              <LogoLight />
            </div>

            <button
              type="button"
              className="btn desktop btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
              id="topnav-hamburger-icon"
              onClick={() => changeLayout()}
            >
              <span className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>

            <button
              type="button"
              className="btn mobile btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
              id="topnav-hamburger-icon"
              onClick={toggleSidebar}
            >
              <span className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>

            <form className="app-search d-none d-md-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  autoComplete="off"
                  id="search-options"
                  onChange={(e) => handleSearch(e)}
                  onFocus={() => {
                    $("#search-dropdown").show();
                  }}
                  onBlur={(e) => handleSearchClose(e)}
                />
                <span className="mdi mdi-magnify search-widget-icon"></span>
                <span
                  className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                  id="search-close-options"
                ></span>
              </div>
              <div
                className="dropdown-menu dropdown-menu-lg"
                id="search-dropdown"
              >
                <SimpleBar style={{ maxHeight: 320 }}>
                  <div>
                    {searchResponse?.products.length > 0 && (
                      <div className="border-bottom">
                        <div className="dropdown-header mt-2">
                          <h6 className="text-overflow mb-2 text-uppercase">
                            Products
                          </h6>
                        </div>
                        {searchResponse.products.map((product, index) => (
                          <Link
                            key={index}
                            to={`/product/${product.id}/details`}
                            className="dropdown-item notify-item"
                          >
                            <i className="ri-shirt-line align-middle fs-18 text-muted me-2"></i>
                            <span>{product.name}</span>
                          </Link>
                        ))}
                      </div>
                    )}

                    {searchResponse?.orders.length > 0 && (
                      <div className="border-bottom">
                        <div className="dropdown-header mt-2">
                          <h6 className="text-overflow mb-2 text-uppercase">
                            Orders
                          </h6>
                        </div>
                        {searchResponse.orders.map((order, index) => (
                          <Link
                            key={index}
                            to={`/order/${order.id}/details`}
                            className="dropdown-item notify-item"
                          >
                            <div className="d-flex">
                              <div className="flex-1">
                                <h6 className="m-0">#{order.order_no}</h6>
                                <span className="fs-11 mb-0 text-muted">
                                  {" "}
                                  <OrderStatus status={order.status} />{" "}
                                </span>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    )}

                    {searchResponse?.customers.length > 0 && (
                      <div className="border-bottom">
                        <div className="dropdown-header mt-2">
                          <h6 className="text-overflow mb-2 text-uppercase">
                            Customers
                          </h6>
                        </div>
                        {searchResponse.customers.map((customer, index) => (
                          <Link
                            key={index}
                            to={`/customers/${customer.id}`}
                            className="dropdown-item notify-item"
                          >
                            <div className="d-flex">
                              <div className="flex-1">
                                <h6 className="m-0">{customer.name}</h6>
                                <span className="fs-11 mb-0 text-muted">
                                  {" "}
                                  {customer.email} - {customer.phone}{" "}
                                </span>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    )}

                    {searchResponse?.invoices.length > 0 && (
                      <div className="border-bottom">
                        <div className="dropdown-header mt-2">
                          <h6 className="text-overflow mb-2 text-uppercase">
                            Invoices
                          </h6>
                        </div>
                        {searchResponse.invoices.map((invoice, index) => (
                          <Link
                            to={`invoice/${invoice.id}`}
                            className="dropdown-item notify-item py-2"
                          >
                            <div className="d-flex">
                              <div className="flex-1">
                                <h6 className="m-0">#{invoice.invoice_id}</h6>
                                <span className="fs-11 mb-0 text-muted">
                                  {invoice.payment_status}
                                </span>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    )}

                    {searchResponse?.transactions.length > 0 && (
                      <div className="border-bottom">
                        <div className="dropdown-header mt-2">
                          <h6 className="text-overflow mb-2 text-uppercase">
                            Transactions
                          </h6>
                        </div>
                        {searchResponse.transactions.map(
                          (transaction, index) => (
                            <Link
                              key={index}
                              to={`/payments/transactions/${transaction.id}`}
                              className="dropdown-item notify-item"
                            >
                              <div className="d-flex">
                                <div className="flex-1">
                                  <span className="fs-11 mb-0">
                                    {" "}
                                    {transaction.reference} -{" "}
                                    {getSymbolFromCurrency(
                                      transaction.currency
                                    ) + formatCurrency(transaction.amount)}{" "}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          )
                        )}
                      </div>
                    )}

                    {searchResponse?.plans.length > 0 && (
                      <div className="border-bottom">
                        <div class="dropdown-header">
                          <h6 class="text-overflow mb-0 text-uppercase">
                            Plans
                          </h6>
                        </div>

                        <div class="dropdown-item bg-transparent text-wrap">
                          {searchResponse.plans.map((plan, index) => (
                            <a
                              key={index}
                              href={`/subscribe/${plan.group_name}/plans`}
                              class="btn btn-soft-primary btn-sm mb-2 rounded-pill"
                            >
                              {plan.name} {plan.group_name} @{" "}
                              {getSymbolFromCurrency("NGN") +
                                formatCurrency(
                                  plan.price -
                                  (plan.discount / 100) * plan.price
                                )}
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </SimpleBar>
              </div>
            </form>
          </div>

          <div className="d-flex align-items-center">
            <div className="dropdown d-md-none topbar-head-dropdown header-item">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-search fs-22"></i>
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="dropdown topbar-head-dropdown ms-1 header-item hide">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-category-alt fs-22"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
                <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="m-0 fw-semibold fs-15">
                        {" "}
                        Connected Apps{" "}
                      </h6>
                    </div>
                    <div className="col-auto">
                      <Link to="" className="btn btn-sm btn-soft-info">
                        View All Apps
                        <i className="ri-arrow-right-s-line align-middle"></i>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="p-2">
                  <div className="row g-0">
                    <div className="col">
                      <Link className="dropdown-icon-item" to="">
                        <img
                          src="/assets/images/brands/github.png"
                          alt="Github"
                        />
                        <span>GitHub</span>
                      </Link>
                    </div>
                    <div className="col">
                      <Link className="dropdown-icon-item" to="">
                        <img
                          src="/assets/images/brands/bitbucket.png"
                          alt="bitbucket"
                        />
                        <span>Bitbucket</span>
                      </Link>
                    </div>
                    <div className="col">
                      <Link className="dropdown-icon-item" to="">
                        <img
                          src="/assets/images/brands/dribbble.png"
                          alt="dribbble"
                        />
                        <span>Dribbble</span>
                      </Link>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="col">
                      <Link className="dropdown-icon-item" to="">
                        <img
                          src="/assets/images/brands/dropbox.png"
                          alt="dropbox"
                        />
                        <span>Dropbox</span>
                      </Link>
                    </div>
                    <div className="col">
                      <Link className="dropdown-icon-item" to="">
                        <img
                          src="/assets/images/brands/mail_chimp.png"
                          alt="mail_chimp"
                        />
                        <span>Mail Chimp</span>
                      </Link>
                    </div>
                    <div className="col">
                      <Link className="dropdown-icon-item" to="">
                        <img
                          src="/assets/images/brands/slack.png"
                          alt="slack"
                        />
                        <span>Slack</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ms-1 header-item d-sm-flex">
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${domain}${storeFrontToken ? `?key=${storeFrontToken}` : ''}`}
                className="btn btn-ghost-secondary d-flex align-items-center light-dark-mode"
                style={{ color: "#b0c4d9" }}
              >
                <i className="ri-store-2-line fs-22"></i>
                <span className="ms-2 desktop">View Site</span>
              </a>
            </div>

            <div className="ms-1 header-item d-none d-sm-flex">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
                onClick={changeTheme}
              >
                <i className="bx bx-moon fs-22"></i>
              </button>
            </div>

            <div className="dropdown topbar-head-dropdown ms-1 header-item notifications-box">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                id="page-header-notifications-dropdown"
                onClick={() => {
                  $("#header-notifications").toggleClass("show");
                }}
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-bell fs-22"></i>
                {unread > 0 && (
                  <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                    {unread}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                )}
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"
                id="header-notifications"
                style={{
                  position: "absolute",
                  inset: "0px 0px auto auto",
                  margin: "0px",
                  transform: "translate3d(0px, 58px, 0px)",
                }}
              >
                <div className="dropdown-head bg-primary bg-pattern rounded-top">
                  <div className="p-3">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="m-0 fs-16 fw-semibold text-white">
                          Notifications
                        </h6>
                      </div>
                      <div className="col-auto dropdown-tabs">
                        <span
                          className="badge badge-soft-light fs-13"
                          style={{ cursor: "pointer" }}
                          onClick={markAllAsRead}
                        >
                          Mark all as read
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-content" id="notificationItemsTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="all-noti-tab"
                    role="tabpanel"
                  >
                    {notifications.length > 0 ? (
                      <SimpleBar style={{ maxHeight: 300 }} className="pe-2">
                        {notifications.map((notification) => (
                          <div
                            className={`text-reset notification-item d-block dropdown-item position-relative ${notification.read === 1 && "active"
                              }`}
                            key={notification.id}
                          >
                            <div className="d-flex">
                              <div className="avatar-xs me-3">
                                <NotificationIcons
                                  notification={notification}
                                />
                              </div>
                              <div className="flex-1">
                                <Link to="#!" className="stretched-link">
                                  <h6 className="mt-0 mb-2 lh-base">
                                    {notification.message}
                                  </h6>
                                </Link>
                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                  <span>
                                    <i className="mdi mdi-clock-outline"></i>{" "}
                                    {formatDateTimeDisplay(
                                      notification.created_date
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="my-3 text-center">
                          <Link
                            to="#"
                            className="btn btn-sm btn-soft-success waves-effect waves-light"
                          >
                            View All Notifications
                            <i className="ri-arrow-right-line align-middle"></i>
                          </Link>
                        </div>
                      </SimpleBar>
                    ) : (
                      <div className="text-center pb-5 mt-2">
                        <div className="w-25 w-sm-50 pt-3 mx-auto">
                          <img
                            src="/assets/images/svg/bell.svg"
                            className="img-fluid"
                            alt="user-pic"
                          />
                        </div>
                        <h6 className="fs-18 fw-semibold lh-base">
                          Yay! You have no notification.
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                className="btn"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  {user.picture ? (
                    <img
                      className="rounded-circle header-profile-user border-2 border"
                      src={user.picture}
                      alt=""
                    />
                  ) : (
                    <img
                      className="rounded-circle header-profile-user"
                      src="/assets/images/users/default.png"
                      alt=""
                    />
                  )}

                  <span className="mobile ms-1">
                    <i className="mdi mdi-chevron-down text-white fs-16 align-middle"></i>
                  </span>
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                      {user.firstname + " " + user.lastname}
                    </span>
                    <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                      {user.email}
                    </span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome {user.firstname}!</h6>
                <Link className="dropdown-item" to="/settings/store-setup">
                  <span className="align-middle fw-bold">{store.name.substring(0, 15)} ({store.store_id})</span>
                </Link>
                <Link className="dropdown-item" to="/settings/profile">
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Profile</span>
                </Link>
                <Link
                  className="dropdown-item"
                  to="/settings/billing-and-subscription"
                >
                  <i className="mdi mdi-credit-card-outline text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Billing & Subscription</span>
                </Link>
                <Link className="dropdown-item" to="/referrals">
                  <i className="mdi mdi-cash text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Refer & earn</span>
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/settings/store-setup">
                  <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Settings</span>
                </Link>
                <a
                  href="mailto:support@taojaa.com"
                  target="_blank"
                  rel="noreferrer"
                  className="dropdown-item"
                >
                  <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Support</span>
                </a>

                <Link className="dropdown-item" to="#" onClick={logoutUser}>
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
