import React, { useContext } from 'react'
import { AppContext } from '../../Contexts/AppContext';
import ModalBox from '../../Components/ModalBox';

const PaymentDetail = ({ show = false, closeModal, saving, payment, updatePayment, handleChange }) => {

    const { paymentStatuses, paymentMethods } = useContext(AppContext)

    return (

        <ModalBox
            show={show}
            handleClose={closeModal}
            title={'Update Payment'}
            closeBtn={<button type="button" className="btn btn-light" onClick={closeModal}> Close</button>}
            saveBtn={
                (<button
                    type="button"
                    disabled={saving}
                    className={`btn btn-success ${saving ? 'disabled' : ''}`}
                    onClick={() => updatePayment()}
                >
                    {saving
                        ? <>
                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> Saving...
                        </> : 'Save'}
                </button>)}
        >
            <div>

                <div className='mb-3'>
                    <label className="form-label form-group"><b>Status</b></label>
                    <select
                        className="form-control"
                        name="status"
                        id="idStatus"
                        value={payment.status}
                        onChange={(e) => handleChange(e)}
                    >
                        <option defaultValue="" defaultChecked>
                            Select Status
                        </option>
                        {paymentStatuses.length > 0 &&
                            paymentStatuses.map((status, index) => {
                                return ((status.id !== 1 && status.id !== 0) ? <option key={index} value={status.id}>{status.name}</option> : null);
                            })}
                    </select>
                </div>

                <div className="mb-3">
                    <label
                        htmlFor="payment-field"
                        className="form-label form-group"
                    >
                        Payment Method
                    </label>
                    <select
                        className="form-control"
                        name="payment_method"
                        value={payment.payment_method}
                        onChange={(e) => handleChange(e)}
                        id="payment-field"
                    >
                        <option defaultValue="">
                            Payment Method
                        </option>
                        {paymentMethods.length > 0 &&
                            paymentMethods.map(({ id, name }) => {
                                return (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                );
                            })}
                    </select>
                </div>
            </div>
        </ModalBox>
    )
}

export default PaymentDetail